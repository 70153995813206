import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

class ContractInspectModel {
  constructor(obj) {
    this.inspectCompleteDate = null;
    this.partnerInspectCompleteDate = null;

    //this.objRaw = null;

    this.calculateNum = 0;
    this.deliveryCompleteDate = ""; // 납품완료일
    this.deliveryHistory = ""; // 납품 내역
    this.description = ""; // 설명 (납품내역)
    this.etc = ""; // 특이사항
    this.calculateDate = ""; // 검수예정일
    this.inspectCompleteDate = ""; // 검수완료일
    this.inspectFile = {};
    this.inspectNum = 0; // 검수번호
    this.inspectPrice = 0; // 검수 요청 가격
    (this.inspectYn = false), // 검수 필요여부
      (this.partnerInspectCompleteDate = ""); // 파트너 검수 완료일
    this.partnerInspectOpinion = "";
    this.regDate = ""; // 등록 날짜
    this.regId = ""; // 등록 아이디
    this.stateCode = ""; // 검수상태 코드
    // this.caclulateDate = ''; // 검수예정일

    if (obj) {
      this.setData(obj);
    }
  }

  setData(obj) {
    //this.objRaw = obj;

    //const { inspectCompleteDate, partnerInspectCompleteDate } = objRaw;
    if (obj.calculateDate) this.calculateDate = obj.calculateDate;
    if (obj.calculateNum) this.calculateNum = obj.calculateNum;
    if (obj.deliveryCompleteDate) this.deliveryCompleteDate = obj.deliveryCompleteDate;
    if (obj.deliveryHistory) this.deliveryHistory = obj.deliveryHistory;
    if (obj.description) this.description = obj.description;
    if (obj.etc) this.etc = obj.etc;
    if (obj.inspectCompleteDate) this.inspectCompleteDate = obj.inspectCompleteDate;
    if (obj.inspectFile) this.inspectFile = obj.inspectFile;
    if (obj.inspectNum) this.inspectNum = obj.inspectNum;
    if (obj.inspectPrice) this.inspectPrice = obj.inspectPrice;
    if (obj.inspectYn) this.inspectYn = obj.inspectYn;
    if (obj.partnerInspectCompleteDate)
      this.partnerInspectCompleteDate = obj.partnerInspectCompleteDate;
    if (obj.partnerInspectOpinion) this.partnerInspectOpinion = obj.partnerInspectOpinion;
    if (obj.regDate) this.regDate = obj.regDate;
    if (obj.regId) this.regId = obj.regId;
    if (obj.stateCode) this.stateCode = obj.stateCode;
    // if(obj.caclulateDate) this.caclulateDate = obj.caclulateDate;
  }

  getData() {
    // const obj = Object.assign( {}, this.objRaw );
    if (this.calculateDate) obj.calculateDate = this.calculateDate;
    if (this.inspectCompleteDate)
      obj.inspectCompleteDate = dateToStringWithTime(this.inspectCompleteDate);
    if (this.partnerInspectCompleteDate)
      obj.partnerInspectCompleteDate = dateToStringWithTime(this.partnerInspectCompleteDate);
    if (this.calculateNum) obj.calculateNum = this.calculateNum;
    if (this.deliveryCompleteDate) obj.deliveryCompleteDate = this.deliveryCompleteDate;
    if (this.deliveryHistory) obj.deliveryHistory = this.deliveryHistory;
    if (this.description) obj.description = this.description;
    if (this.etc) obj.etc = this.etc;
    if (this.inspectCompleteDate)
      obj.inspectCompleteDate = stringToDateWithTime(this.inspectCompleteDate);
    if (this.inspectFile) obj.inspectFile = this.inspectFile;
    if (this.inspectNum) obj.inspectNum = this.inspectNum;
    if (this.inspectPrice) obj.inspectPrice = this.inspectPrice;
    if (this.inspectYn) obj.inspectYn = this.inspectYn;
    if (this.partnerInspectCompleteDate)
      obj.partnerInspectCompleteDate = stringToDateWithTime(this.partnerInspectCompleteDate);
    if (this.partnerInspectOpinion) obj.partnerInspectOpinion = this.partnerInspectOpinion;
    if (this.regDate) obj.regDate = this.regDate;
    if (this.regId) obj.regId = this.regId;
    if (this.stateCode) obj.stateCode = this.stateCode;
    // if(this.caclulateDate) obj.caclulateDate = this.caclulateDate;

    return obj;
  }
}

export default ContractInspectModel;
